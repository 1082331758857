<template>
  <div>
    <div class="columns is-gapless is-centered is-mobile">
      <div v-if="adventure" class="column is-12-mobile is-10-tablet is-8-desktop is-6-widescreen is-6-fullhd mb-4">
        <figure v-if="adventure" class="image-sticky image" style="z-index: -100;">
          <img
          class="fit image-border-style"
          v-bind:src="adventure.previewImageUrl"
          alt="Placeholder image">
        </figure>

        <div class="container px-3 py-2 pb-6 main-border-style has-background-white overlap-main">
          <div class="pl-3">
            <div class="container py-0">
              <div class="notranslate title is-4 has-baskerville-font has-text-weight-bold has-text-brand-color pt-5 mb-2">{{ adventure.name }}</div>
              <div v-if="adventure.allowTranslation && !adventure.defaultLanguage" @click="goToTranslator()" class="is-size-6 has-text-info is-clickable">
                <i class="fas fa-language fa-fw mr-2"></i>
                {{ t(`Click here to change ${gameText.toLowerCase()} language`) }}
              </div>
            </div>
          </div>
          <div class="container px-3 pt-2">
            <div
            class="notranslate"
            v-markdown>{{ adventure.briefing }}</div>
          </div>
        </div>

        <div v-if="adventure.isActive" class="container has-text-centered has-background-white px-5 mb-4">
          <button v-if="showRejoinButton" @click="goToLobby()" class="button mb-2 is-primary-colors has-text-weight-semibold is-large is-fullwidth custom-button-colour">
            {{ t(`Re-join ${gameText.toLowerCase()}`) }}
            <i class="fas fa-sign-in-alt ml-2"></i>
          </button>
          <button
          v-if="adventure.accessType === 'public'"
          @click="createTeam('Guest', null)"
          class="button is-primary-colors has-text-weight-semibold is-large is-fullwidth custom-button-colour"
          v-bind:class="{
            'is-light is-loading': loading
          }"
          >
            {{ t(`Start`) }}
          </button>
          <div v-else-if="showRejoinButton" @click="toggleCreateTeamScreen()"
          class="is-clickable has-text-weight-bold is-size-5 mt-4">
            {{ t(`Start new ${gameText.toLowerCase()}`) }}
            <i class="fas fa-history ml-2"></i>
          </div>
          <button v-else-if="!showRejoinButton" @click="toggleCreateTeamScreen()"
          class="button is-primary-colors has-text-weight-semibold is-large is-fullwidth custom-button-colour">
            {{ t(`Click here to start`) }}
          </button>
        </div>
        <div v-else class="container has-text-centered px-4 mb-4">
          <div v-if="adventure.isActiveNotice" class="notification mb-1 py-2">
            {{ adventure.isActiveNotice }}
          </div>
          <button
          class="button is-grey has-text-weight-semibold is-large is-fullwidth custom-button-colour"
          :disabled="true"
          >
            {{ t(`${gameText} Inactive`) }}
          </button>
        </div>

        <div class="px-6 my-6"></div>
      </div>

      <div v-if="!adventure" class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd">
        <figure class="image-sticky image is-2by1 has-background-grey-light loading">
        </figure>
        <div class="container px-3 main-border-style has-background-white">
          <div class="container pt-4">
            <div class="title-skeleton loading"></div>
            <div class="subtitle-skeleton loading mt-2"></div>
            <div class="subtitle-skeleton loading mt-2"></div>

            <div class="subtitle-skeleton loading mt-4"></div>
            <div class="subtitle-skeleton loading mt-2"></div>

            <div class="title-skeleton loading mt-5"></div>
            <div class="subtitle-skeleton loading mt-3"></div>
            <div class="subtitle-skeleton loading mt-2"></div>

            <div class="subtitle-skeleton loading mt-2"></div>
            <div class="subtitle-skeleton loading mt-2"></div>
          </div>
          <div class="container px-3">

          </div>
        </div>
      </div>
      <transition name="fade">
        <div
        class="modal create-game-modal"
        v-if="showCreateTeamScreen"
        v-bind:class="{'is-active': showCreateTeamScreen}"
        >
          <div @click="toggleCreateTeamScreen()" class="modal-background pointer"></div>
          <div class="modal-content">
            <div class="card has-rounded-top-corders">
              <div class="card-content mb-6 pb-6">
                <div class="container has-text-weight-semibold has-text-centered">
                  <h3 class="title is-4 has-baskerville-font has-text-weight-bold has-text-brand-color mb-5">
                    {{ t(yourDetailsText) }}
                  </h3>
                </div>
                <div class="container mb-2">
                  <FormInput v-if="adventure.requireAccessCodes" :size="'medium'" :title="'Access Code'" :type="'text'" placeholder="" :value="accessCode" v-on:update="accessCode = $event"/>
                  <FormInput :size="'medium'" :title="playerNameText" :type="'text'" placeholder="" :value="name" v-on:update="name = $event"/>
                  <FormInput
                    v-if="isTeam && (!adventure.maxPlayersPerTeam || (adventure.maxPlayersPerTeam && adventure.maxPlayersPerTeam > 1))"
                    v-on:update="teamName = $event"
                    :size="'medium'"
                    :title="teamNameText"
                    :type="'text'"
                    :value="teamName"
                    placeholder=""
                    :helptext="`${t(`Your teammates will join via a link given to you in the ${gameText.toLowerCase()} lobby`)}`"
                  />
                  <label
                    v-if="!adventure.maxPlayersPerTeam || (adventure.maxPlayersPerTeam && adventure.maxPlayersPerTeam > 1)"
                    class="checkbox is-size-6 mb-2"
                  >
                    <input type="checkbox" v-model="isTeam" class="mr-1">
                    {{ t(`I am playing as a team`) }}
                    <i class="fas fa-users ml-1"></i>
                  </label>
                  <FormInput v-if="clans" :title="'Clan'" :type="'selection'" :value="clan" :selections="clans" v-on:update="clan = $event"/>
                  <FormInput
                    v-if="adventure.sendTeamLinkEmail === true || adventure.sendTeamLinkEmail && adventure.sendTeamLinkEmail.includes('Yes')"
                    :size="'medium'"
                    title="Email"
                    :type="'text'"
                    :value="email"
                    :placeholder="`email@domain.com ${ adventure.sendTeamLinkEmail === true || adventure.sendTeamLinkEmail && adventure.sendTeamLinkEmail.includes('optional') ? '(optional)' : '' }`"
                    helptext="Send a link to your email in case this tab is accidentally closed."
                    v-on:update="email = $event"
                  />
                </div>
                <div v-if="true" class="container has-text-centered">
                  <p class="help is-danger">{{ errorMessage }}</p>
                </div>
                <div
                @click="(!name || !isValidEmail || loading || clans && !clan) ? null : createTeam(null, null)"
                class="button is-primary-colors has-text-weight-semibold is-fullwidth custom-button-colour mb-2"
                v-bind:class="{
                  'is-light is-loading': loading
                }"
                :disabled="!name || !isValidEmail || clans && !clan"
                >
                  {{ t(`Next`) }}
                </div>
                <div
                v-if="isTeam === false && adventure.accessType === 'public' && !name"
                @click="setRandomName()"
                class="button is-primary is-light has-text-weight-semibold is-fullwidth mb-2"
                v-bind:class="{
                  'is-light is-loading': loading
                }"
                >
                  {{ t(`Use Random Name`) }}
                </div>
                <div
                @click="toggleCreateTeamScreen()"
                class="button is-light has-text-weight-semibold is-fullwidth">
                  {{ t(`Return`) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import FormInput from '@/components/forms/FormInput'

export default {
  name: 'AdventureIntro',
  components: {
    FormInput
  },
  data() {
    return {
      isTeam: true,
      errorMessage: null,
      loading: false,
      teamName: '',
      name: '',
      email: '',
      clan: null,
      adventure: null,
      showCreateTeamScreen: false,
      sessionId: this.$route.params.sessionId,
      adventureId: this.$route.params.adventureId,
      accessCode: null
    }
  },
  computed: {
    showRejoinButton(){
      if(
        this.adventure &&
        this.$store.state.adventureId &&
        this.$store.state.adventureId === this.adventure.adventureId &&
        this.$store.state.teamCode &&
        this.$store.state.userName
      ){
        return true
      }
      return false
    },
    gameText(){
      return this.adventure?.uiMods?.game ? this.adventure?.uiMods?.game : 'Game'
    },
    yourDetailsText(){
      return this.adventure?.uiMods?.yourDetailsText ? this.adventure?.uiMods?.yourDetailsText : 'Your details'
    },
    playerNameText(){
      return this.adventure?.uiMods?.playerNameText ? this.adventure?.uiMods?.playerNameText : 'My name is...'
    },
    teamNameText(){
      return this.adventure?.uiMods?.teamNameText ? this.adventure?.uiMods?.teamNameText : 'Team Name'
    },
    clans() {
      if (!this.adventure?.uiMods?.clans) return null
      return this.adventure.uiMods.clans.split(',').map(x => x.trim())
    },
    isValidEmail(){
      const pattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
      if (this.email && pattern.test(this.email)) return true
      if (
        this.adventure.sendTeamLinkEmail &&
        (this.adventure.sendTeamLinkEmail.includes('optional') && (!this.email || this.email === ''))
      ) {
        return true
      }
      if (
        this.adventure.sendTeamLinkEmail &&
        (this.adventure.sendTeamLinkEmail.includes('mandatory') && this.email && pattern.test(this.email))
      ) {
        return true
      }
      if (!this.adventure.sendTeamLinkEmail || this.adventure.sendTeamLinkEmail === 'No') return true
      return false
    }
  },
  mounted() {
    if(this.$store.state.adventures.length > 0){
      this.adventure = this.$store.state.adventures.filter(obj => {
        return obj.adventureId === this.$route.params.adventureId
      })[0]
    } else if (this.adventureId) {
      this.getAdventureByAdventureId()
    } else if (this.sessionId) {
      this.getAdventureBySessionId()
    }

    // because on Chrome when you enter this page it does not go to the top
    setTimeout(() => {
      window.scrollTo(0,0)
    })
  },
  methods: {
    goTo(routeName){
      this.$router.replace({ name: routeName })
    },
    goToTranslator(){
      this.$store.commit('updateTranslatorReturnPath', this.$route.path)
      this.$router.push({ name: 'Translation' })
    },
    goToLobby(){
      this.$router.push({
        name: 'Lobby',
        params: {
          teamCode: this.$store.state.teamCode
        }
      })
    },
    toggleCreateTeamScreen() {
      if(!this.sessionId){
        alert('This is a welcome page preview. You cannot start a game here.')
        return
      }
      if (
        this.adventure.uiMods &&
        (this.adventure.uiMods.customButtonBackgroundColour || this.adventure.uiMods.customButtonFontColour)
      ) {
        this.setCustomButtonColour(
          this.adventure.uiMods.customButtonBackgroundColour,
          this.adventure.uiMods.customButtonFontColour
        )
      }
      this.showCreateTeamScreen = !this.showCreateTeamScreen
    },
    getAdventureBySessionId() {
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'get-adventure-id-by-session-id',
        sessionId: this.sessionId.length === 4 ? this.sessionId.toUpperCase().trim() : this.sessionId
      }).then(result => {
        if (result.data.adventureId) {
          this.adventureId = result.data.adventureId
          this.getAdventureByAdventureId()
        } else {
          alert('Invalid code, please try again.')
          this.$router.go(-1)
        }
      })
    },
    getAdventureByAdventureId(){
      console.log('hi')
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'get-adventure-by-adventure-id',
        adventureId: this.adventureId,
        sessionId: this.sessionId.length === 4 ? this.sessionId.toUpperCase().trim() : this.sessionId
      }).then(result => {
        console.log('bywww')
        if(result.data === 'Adventure not found.'){
          alert('PlayTour not found, redirecting to homepage...')
          this.$router.push({ name: 'Start' })
        } else {
          if(result.data){
            this.adventure = result.data
            this.isTeam = !this.adventure.maxPlayersPerTeam || (this.adventure.maxPlayersPerTeam && this.adventure.maxPlayersPerTeam !== 1)
            document.title = `${this.adventure.name}`

            if (
              result.data.allowTranslation &&
              result.data.defaultLanguage &&
              this.$route.fullPath !== `${this.$route.path}#googtrans(${result.data.defaultLanguage})`
            ){
              this.$router
              .replace(`${this.$route.path}#googtrans(${result.data.defaultLanguage})`)
              .catch(err => {
                return err
              })
              window.location.reload()
            }
            if (result.data.startingLocationLatLon) {
              this.center = [
                result.data.startingLocationLatLon.lat, result.data.startingLocationLatLon.lon
              ]
            }
            this.$store.commit('updateLobbyTitleAndLogo', {
              title: result.data.title,
              logo: result.data.logo
            })
            if (result.data.uiMods && (result.data.uiMods.welcomeScreenTitle || result.data.uiMods.welcomeScreenLogo)) {
              this.$store.commit('updateLobbyTitleAndLogo', {
                title: result.data.uiMods.welcomeScreenTitle,
                logo: result.data.uiMods.welcomeScreenLogo
              })
            }
            if (result.data.uiMods && result.data.uiMods.customFontUrl) {
              this.setFontFamily(result.data.uiMods.customFontUrl)
            }
            console.log('result.data.uiMods.customButtonBackgroundColour', result.data.uiMods.customButtonBackgroundColour)
            if (
              result.data.uiMods &&
              (result.data.uiMods.customButtonBackgroundColour || result.data.uiMods.customButtonFontColour)
            ) {
              this.setCustomButtonColour(
                result.data.uiMods.customButtonBackgroundColour,
                result.data.uiMods.customButtonFontColour
              )
            }

            if(this.sessionId && this.sessionId.substring(0, 7) === 'TESTING'){
              let stage = null
              if(this.sessionId.length > 8){
                stage = parseInt(this.sessionId.substring(8, this.sessionId.length))
              }
              this.sessionId = null
              this.teamName = 'TESTING'
              this.name = 'TESTING'
              this.isTeam = true
              this.createTeam('TESTING', stage)
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    setRandomName() {
      const randomNames = ['Susan', 'John', 'Tommy', 'Sapphire', 'Ruby', 'Mary']
      this.name = randomNames[Math.floor(Math.random() * randomNames.length)]
    },
    createTeam(name, stage){
      this.errorMessage = null
      this.loading = true
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'create-team',
        teamName: this.isTeam ? this.teamName : this.name,
        name: name ? name : this.name,
        email: this.email ? this.email : null,
        clan: this.clan,
        adventureId: this.adventureId,
        sessionId: this.sessionId,
        stage: stage,
        accessCode: this.accessCode
      }).then(res => {
        if(res.data.teamCode){
          this.$store.commit('updateUserName', name ? name : this.name)
          this.$store.commit('updateTeamCode', res.data.teamCode)
          this.$store.commit('updateAdventureName', this.adventure.name)
          this.$store.commit('updateAdventureId', this.adventureId)
          this.$store.commit('updateIsTeam', this.isTeam ? true : false)
          this.$router.push({
            name: 'Lobby',
            params: {
              teamCode: res.data.teamCode
            }
          })
        }
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    },
    setFontFamily(fontUrl) {
      const newFont = new FontFace('userCustom', `url(${fontUrl})`)
      newFont.load().then(function(loadedFont) {
        document.fonts.add(loadedFont)
        setInterval(function(){
          document.getElementsByTagName('*').forEach(div => {
            if (
              div.style.fontFamily !== 'userCustom' &&
              div.tagName !== 'I'
            ) {
              div.style.fontFamily = "userCustom"
            }
          })
        }, 1000)
      })
    },
    setCustomButtonColour(backgroundColour, textColour) {
      setTimeout(() => {
        Array.from(document.getElementsByClassName('custom-button-colour')).forEach(div => {
          if(backgroundColour && textColour){
            div.setAttribute( 'style', `background-color: ${backgroundColour} !important; color: ${textColour} !important` )
          } else if (backgroundColour){
            div.setAttribute( 'style', `background-color: ${backgroundColour} !important` )
          } else if (textColour){
            div.setAttribute( 'style', `color: ${textColour} !important` )
          }
          // div.style.fontFamily = "'userCustom'"
        })
      }, 1500)
    }
  }
}
</script>

<style scoped>
.image-sticky{
  position: sticky;
  top: 53px;
}

.fit {
  object-fit: cover;
}

.main-border-style {
  border-radius: 15px 15px 0px 0px;
}

.image-border-style {
  border-radius: 0px 0px 15px 15px;
  z-index: 29;
}

.overlap-main {
  transform: translateY(2px);
}

.create-game-modal {
  height: 100vh;
  flex-direction: column-reverse !important;
  justify-content: end !important;
  z-index: 31;
}
</style>
